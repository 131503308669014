<template>
  <div class="bigBox">
    <div class="block">
      <el-carousel height="779px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 779px" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="block1">
        <div class="h5">{{ $t('message.出海首选建站平台') }}</div>
        <div class="list">
          <div class="li row-b">
            <div class="info">
              <div class="no row-s">
                <span style="font-size: 48px;font-weight: 600">01</span><div style="width: 80px;height: 2px;background: #000;margin: 0 10px"></div>{{ $t('message.快速搭建跨境独立站') }}
              </div>
              <div class="infoContent">
                  <div>• {{ $t('message.H5+移动端') }}</div>
                  <div>• {{ $t('message.多行业独立站精美模板免费套用') }}</div>
                  <div>• {{ $t('message.网站自动适配移动端和web端') }}</div>
              </div>
            </div>
            <img src="@/assets/hw1.png" />
          </div>
          <div class="li row-b">
            <img src="@/assets/hw2.png" />
            <div class="info">
              <div class="no row-s">
                <span style="font-size: 48px;font-weight: 600">02</span><div style="width: 80px;height: 2px;background: #000;margin: 0 10px"></div>{{ $t('message.支持本地多种货币') }}
              </div>
              <div class="infoContent">
                <div>• {{ $t('message.微信支付宝') }}</div>
                <div>• {{ $t('message.银行卡支付') }}</div>
                <div>• {{ $t('message.10种语言') }}</div>
              </div>
            </div>
          </div>
          <div class="li row-b">
            <div class="info">
              <div class="no row-s">
                <span style="font-size: 48px;font-weight: 600">03</span><div style="width: 80px;height: 2px;background: #000;margin: 0 10px"></div>{{ $t('message.丰富的营销玩法') }}
              </div>
              <div class="infoContent">
                <div>• {{ $t('message.优惠券') }}</div>
                <div>• {{ $t('message.一键分享微信') }}</div>
              </div>
            </div>
            <img src="@/assets/hw3.png" />
          </div>
          <div class="li row-b">
            <img src="@/assets/hw4.png" />
            <div class="info">
              <div class="no row-s">
                <span style="font-size: 48px;font-weight: 600">04</span><div style="width: 80px;height: 2px;background: #000;margin: 0 10px"></div>{{ $t('message.更好的消费与分享') }}
              </div>
              <div class="infoContent">
                <div>• {{ $t('message.消费得积分') }}</div>
                <div>• {{ $t('message.搭建二级分销体系') }}</div>
              </div>
            </div>
          </div>
          <div class="li row-b">
            <div class="info">
              <div class="no row-s">
                <span style="font-size: 48px;font-weight: 600">05</span><div style="width: 80px;height: 2px;background: #000;margin: 0 10px"></div>{{ $t('message.酒店餐饮赋能') }}
              </div>
              <div class="infoContent">
                <div>• {{ $t('message.酒店新零售') }}</div>
                <div>• {{ $t('message.自助扫码点餐') }}</div>
              </div>
            </div>
            <img src="@/assets/hw5.png" />
          </div>
          <div class="li row-b">
            <img src="@/assets/hw6.png" />
            <div class="info">
              <div class="no row-s">
                <span style="font-size: 48px;font-weight: 600">06</span><div style="width: 80px;height: 2px;background: #000;margin: 0 10px"></div>{{ $t('message.专业团队') }}
              </div>
              <div class="infoContent">
                <div>• {{ $t('message.强大的研发实力') }}</div>
                <div>• {{ $t('message.团队深耕东南亚市场多年') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home4content" :style="{ backgroundImage: `url(${a3})`}">
      <div class="H5">{{ $t('message.现在加入我们') }}</div>
      <div class="row-c">
        <div class="freeStoreOpen" @click="freeStoreOpen">
          {{ $t('message.免费开店') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Tab",
  data() {
    return {
      a3:require("@/assets/goBg2.png"),
      img_arr:[
        {
          img: {
            En: require("../assets/image5_en.png"),
            zh: require("../assets/image5.png"),
          },
          current: '', // will be updated in created() method
        },
      ],
    };
  },
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }
    }
  },
  created(){
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
  },
  computed: {

  },
  methods:{
    freeStoreOpen(){
      window.open('https://selleradmin.whatsbuy.com/#/login?register=true')
    },
  },
};
</script>

<style scoped lang="less">
.bigBox{
  zoom: 80%;
}
.content{
  .block1{
    margin: 0 170px;
    .h5{
      margin: 96px 0 99px 0;
      font-weight: 600;
      font-size: 53px;
      color: #202020;
      text-align: center;
    }
    .list{
      .li{
        align-items: flex-start;
        margin-bottom: 60px;
        .info{

          .no{
            width: 700px;
            font-size: 32px;
            color: #202020;
            margin-bottom: 21px;
            font-weight: 600;
          }
          .infoContent{
            width: 516px;
            font-size: 24px;
            font-weight: 400;
            color: #000;
            line-height: 40px;
          }
        }
        img{
          width: 613px;
          height: 344px;
          border-radius: 13px 13px 13px 13px;
        }
      }
    }
  }
}
.home4content{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 445px;

  .H5{
    padding: 100px 0 0;
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    color: #FFFFFF;
  }
  .freeStoreOpen{
    margin-top: 60px;
    //width: 333px;
    height: 75px;
    padding: 0 20px;
    background: #FFFFFF;
    box-shadow: 0px 3 11px 0px rgba(0,60,102,0.4);
    border-radius: 5px 5px 5px 5px;
    font-weight: 600;
    font-size: 30px;
    color: #9A40F4;
    line-height: 75px;
    text-align: center;
    cursor:pointer;
  }
}
@media screen and (min-width:1450px){
  .bigBox{
    zoom: 80%;
  }
  .content{
    .block1{
      margin: 0 300px;
      .h5{
        margin: 96px 0 99px 0;
        font-weight: 600;
        font-size: 53px;
        color: #202020;
        text-align: center;
      }
      .list{
        .li{
          align-items: flex-start;
          margin-bottom: 60px;
          .info{

            .no{
              width: 700px;
              font-size: 32px;
              color: #202020;
              margin-bottom: 21px;
              font-weight: 600;
            }
            .infoContent{
              width: 516px;
              font-size: 24px;
              font-weight: 400;
              color: #000;
              line-height: 40px;
            }
          }
          img{
            width: 613px;
            height: 344px;
            border-radius: 13px 13px 13px 13px;
          }
        }
      }
    }
  }
  .home4content{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 445px;

    .H5{
      padding: 100px 0 0;
      text-align: center;
      font-weight: 700;
      font-size: 50px;
      color: #FFFFFF;
    }
    .freeStoreOpen{
      margin-top: 60px;
      //width: 333px;
      height: 75px;
      padding: 0 20px;
      background: #FFFFFF;
      box-shadow: 0px 3 11px 0px rgba(0,60,102,0.4);
      border-radius: 5px 5px 5px 5px;
      font-weight: 600;
      font-size: 30px;
      color: #9A40F4;
      line-height: 75px;
      text-align: center;
      cursor:pointer;
    }
  }
}
</style>
