<template>
  <div class="bigBox">
    <div class="bottom">
      <div class="block1">
        <div class="list row-s">
          <div class="logo_box">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="listInfo row-s">
            <div class="li">
              <div class="label">
                {{ $t('message.行业解决方案') }}
              </div>
              <a class="txt" @click="switchNavigation(0)">
                {{ $t('message.行业痛点') }}
              </a>
              <a class="txt" @click="switchNavigation(0)">
                {{ $t('message.客户案例') }}
              </a>
              <a class="txt" href="https://selleradmin.whatsbuy.com/">
                {{ $t('message.成为合作伙伴') }}
              </a>
            </div>
            <div class="li">
              <div class="label">
                {{ $t('message.功能优势') }}
              </div>
              <a class="txt" @click="switchNavigation(1)">
                {{ $t('message.独立建站') }}
              </a>
              <a class="txt" @click="switchNavigation(2)">
                {{ $t('message.酒店售货机') }}
              </a>
            </div>
            <div class="li">
              <div class="label">
                {{ $t('message.帮助中心') }}
              </div>
              <a class="txt" @click="switchNavigation(3)">
                {{ $t('message.使用指南') }}
              </a>
              <a class="txt" @click="switchNavigation(3)">
                {{ $t('message.常见问题') }}
              </a>
            </div>
            <div class="li" style="margin-right: 135px">
              <div class="label">
                {{ $t('message.合作') }}
              </div>
              <a class="txt" @click="switchNavigation(4)">
                {{ $t('message.公司简介') }}
              </a>
              <a class="txt" @click="switchNavigation(4)">
                {{ $t('message.联系我们') }}
              </a>
            </div>
            <div class="li" style="margin-right: 53px">
              <div class="label">
                {{ $t('message.关注我们') }}
              </div>
              <div class="imgBox row-c row-d">
                <img src="@/assets/store.png" alt="" />
                <div style="text-align: center">{{ $t('message.微信公众号') }}</div>
              </div>
            </div>
            <div class="li" style="margin-right: 0">
              <div class="label">
                {{ $t('message.联系我们') }}
              </div>
              <div class="imgBox row-c row-d">
                <img src="@/assets/whatsbuy_code.png" alt="" />
                <div style="text-align: center">{{ $t('message.微信客服') }}</div>
              </div>
            </div>

          </div>
        </div>
        <div class="home5">
          <div class="h5 row-s">{{ $t('message.友情链接') }}： <a style="margin-right: 20px" href="https://www.bawanli.com/">{{ $t('message.惜时网络') }}</a>   <a>{{ $t('message.八万里') }}</a></div>
          <div class="home5content">
            <div class="row-c" style="margin-bottom: 20px">
              <div>{{ $t('message.联系电话') }}：0731-8550-8601</div>
              <div style="margin:0 30px">{{ $t('message.邮箱') }}：xishiwhatsbuy@gmail.com</div>
              <div>{{ $t('message.地址') }}：{{ $t('message.湖南省长沙市岳麓区街道中建智慧产业园') }}</div>
            </div>

            <div class="row-c">
              <div style="margin-right: 30px">{{ $t('message.长沙好滴科技有限公司©版权所有') }}</div>
              <a href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备2023016916号-1</a>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  data() {
    return {
      actMenu:0,
      lang:'zh'
    };
  },
  computed: {

  },
  methods:{
    switchLanguage(lang){
      this.lang=lang
    },
    switchNavigation(index){
      this.actMenu=index
      this.$emit('switchNavigation',index)
    },
  },
};
</script>

<style scoped lang="less">
.bigBox{
  zoom: 80%;
}
.block1{
  margin: 0 170px;
}
// 页脚
.bottom {
  background: rgba(22, 30, 48, 1);
  color: #fff;
  //height: 536px;
  .list{
    padding: 48px 0 0;
    .logo_box{
      img{
        width: 247px;
        height: 54px;
        margin-bottom: 107px;
      }
    }
    .listInfo{
      align-items: flex-start;
      margin-left: 70px;
      .li{
        color: #fff;
        margin-right: 20px;
        .label{
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 10px;
        }
        .txt{
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        .imgBox{
          img{
            width: 192px;
            height: 192px;
            margin-bottom: 16px;
          }
          color: #fff;
          font-size: 18px;


        }
      }
    }
  }
  .home5{
    padding-bottom: 30px;
    .h5{
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      padding-bottom: 12px;
      border-bottom: 1px solid #D0D0D0;
    }
    .home5content{
      padding: 40px 148px 0;
      font-size: 18px;
      color: #fff;
    }
  }
}
@media screen and (min-width:1450px){
  .bigBox{
    zoom: 80%;
  }
  .block1{
    margin: 0 170px;
  }
  // 页脚
  .bottom {
    background: rgba(22, 30, 48, 1);
    color: #fff;
    //height: 536px;
    .list{
      padding: 48px 0 0;
      .logo_box{
        img{
          width: 247px;
          height: 54px;
          margin-bottom: 107px;
        }
      }
      .listInfo{
        align-items: flex-start;
        margin-left: 70px;
        .li{
          color: #fff;
          margin-right: 180px;
          .label{
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 10px;
          }
          .txt{
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .imgBox{
            img{
              width: 192px;
              height: 192px;
              margin-bottom: 16px;
            }
            color: #fff;
            font-size: 18px;


          }
        }
      }
    }
    .home5{
      padding-bottom: 30px;
      .h5{
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        padding-bottom: 12px;
        border-bottom: 1px solid #D0D0D0;
      }
      .home5content{
        padding: 40px 148px 0;
        font-size: 18px;
        color: #fff;
      }
    }
  }
}
</style>
