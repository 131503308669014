<template>
  <div class="bigBox">
    <div class="block">
      <el-carousel height="779px">
        <el-carousel-item v-for="(item, index) in img_arr" :key="index">
          <div class="item">
            <img :src="item.current" style="width: 100%;height: 779px" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="content" :style="{ backgroundImage: `url(${a2})`}">
      <div class="block1">
        <div class="home1">
          <div class="h5">{{ $t('message.什么人可以来') }}</div>
          <div class="homeMenu row-b">
            <div :class="['label',home1Index==0?'act':'']" @click="switchHome1(0)">
              {{ $t('message.新手建站') }}
            </div>
            <div :class="['label',home1Index==1?'act':'']" @click="switchHome1(1)">
              {{ $t('message.传统平台卖家') }}
            </div>
            <div :class="['label',home1Index==2?'act':'']" @click="switchHome1(2)">
              {{ $t('message.小额批发商') }}
            </div>
            <div :class="['label',home1Index==3?'act':'']" @click="switchHome1(3)">
              {{ $t('message.跨境SOHO') }}
            </div>
            <div :class="['label',home1Index==4?'act':'']" @click="switchHome1(4)">
              {{ $t('message.酒店/餐饮行业赋能人财') }}
            </div>
          </div>
          <div class="homeInfo row-s">
<!--            <img :src="home1Index==0?homeImage:home1Index==1?'@/assets/homeImage1.png':home1Index==2?'@/assets/homeImage2.png':home1Index==3?'@/assets/homeImage3.png':'@/assets/homeImage4.png'"></img>-->
            <img src="@/assets/homeImage.png" v-if="home1Index==0" />
            <img src="@/assets/homeImage1.png" v-if="home1Index==1" />
            <img src="@/assets/homeImage2.png" v-if="home1Index==2" />
            <img src="@/assets/homeImage3.png" v-if="home1Index==3" />
            <img src="@/assets/homeImage4.png" v-if="home1Index==4" />
            <div class="contentInfo row-d row-s">
              <div class="contentH8">
                {{home1Index==0?$t('message.新手建站'):home1Index==1?$t('message.传统平台卖家'):home1Index==2?$t('message.小额批发商'):home1Index==3?$t('message.跨境SOHO'):$t('message.酒店/餐饮行业赋能人财')}}
              </div>
              <div class="contentTxt">
                {{home1Index==0?$t('message.我是个新手'):home1Index==1?$t('message.我是传统卖家'):home1Index==2?$t('message.我是小额批发商'):home1Index==3?$t('message.我是一名老站长'):$t('message.我是个酒店老板')}}
              </div>
              <div class="freeStoreOpen" @click="freeStoreOpen">
                {{ $t('message.免费开店') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home2content" :style="{ backgroundImage: `url(${a1})`}">
      <div class="block1">
        <div class="home2">
          <div class="h5">{{ $t('message.我们能帮助您解决什么问题') }}</div>
          <div class="homeInfo row-a">
            <div class="li row-s">
              <img src="@/assets/homeLogo1.png" />
              <div class="home2Info row-s row-d">
                <div class="txt">{{ $t('message.链接全球客户') }}</div>
                <div class="homeTxt">{{ $t('message.使用营销工具') }}</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/homeLogo2.png" />
              <div class="home2Info row-s row-d">
                <div class="txt">{{ $t('message.打造您的品牌') }}</div>
                <div class="homeTxt">{{ $t('message.创建自己的品牌名字') }}</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/homeLogo3.png" />
              <div class="home2Info row-s row-d">
                <div class="txt">{{ $t('message.丰富营销手段') }}</div>
                <div class="homeTxt">{{ $t('message.单一营销手段') }}</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/homeLogo4.png" />
              <div class="home2Info row-s row-d">
                <div class="txt">{{ $t('message.酒店/餐饮赋能') }}</div>
                <div class="homeTxt">{{ $t('message.上门送货') }}</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/homeLogo5.png" />
              <div class="home2Info row-s row-d">
                <div class="txt">{{ $t('message.收款困难') }}</div>
                <div class="homeTxt">{{ $t('message.跨境资金收款困难') }}</div>
              </div>
            </div>
            <div class="li row-s">
              <img src="@/assets/homeLogo6.png" />
              <div class="home2Info row-s row-d">
                <div class="txt">{{ $t('message.开发成本过高') }}</div>
                <div class="homeTxt">{{ $t('message.单独开发自有商城难度系数大') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home3content">
      <div class="block1">
        <div class="H5">{{ $t('message.合作伙伴') }}</div>
        <div class="row-c topOne">
          <img src="@/assets/partner1.png" style="margin-right: 40px" />
          <img src="@/assets/partner2.png" />
        </div>
        <div class="row-c topTwo">
          <img src="@/assets/partner3.png"  />
          <img src="@/assets/partner4.png" style="margin:0 40px;width: 509px;" />
          <img src="@/assets/partner5.png" />
        </div>
        <div class="row-c topThree">
          <img src="@/assets/partner6.png" style="margin-right: 40px;" />
          <img src="@/assets/partner7.png" style="margin-right: 40px;" />
          <img src="@/assets/partner8.png" style="margin-right: 40px;" />
          <img src="@/assets/partner9.png" />
        </div>
        <div class="row-c topFive">
          <img src="@/assets/partner10.png" style="margin-right: 40px;" />
          <img src="@/assets/partner11.png" style="margin-right: 40px;" />
          <img src="@/assets/partner12.png" />
        </div>
      </div>
    </div>

    <div class="home4content" :style="{ backgroundImage: `url(${a3})`}">
      <div class="H5">{{ $t('message.现在加入我们') }}</div>
      <div class="row-c">
        <div class="freeStoreOpen" @click="freeStoreOpen">
          {{ $t('message.免费开店') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Tab",
  props:{
    lang: {
      type:String,
      default:'zh'
    }
  },
  watch:{
    lang(newValue){
      for (const item of this.img_arr) {
        item.current = item.img[newValue]
      }
    }
  },
  data() {
    return {
      img_arr: [
        {
          img: {
            En: require("../assets/image1_en.png"),
            zh: require("../assets/image1.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          img: {
            En: require("../assets/image2_en.png"),
            zh: require("../assets/image2.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          img: {
            En: require("../assets/image3_en.png"),
            zh: require("../assets/image3.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          img: {
            En: require("../assets/image4_en.png"),
            zh: require("../assets/image4.png"),
          },
          current: '', // will be updated in created() method
        },
      ],
      home1Index:0,
      a1:require("@/assets/home2Bg.png"),
      a2:require("@/assets/home1Bg.png"),
      a3:require("@/assets/goBg.png")
    };
  },
  computed: {

  },
  created() {
    for (const item of this.img_arr) {
      item.current = item.img[this.lang]
    }
  },
  methods:{
    switchHome1(index){
      this.home1Index=index
    },
    freeStoreOpen(){
      window.open('https://selleradmin.whatsbuy.com/#/login?register=true')
    },
  },
};
</script>

<style scoped lang="less">
.bigBox{
  zoom: 80%;
}
.content {
  text-align: center;
  box-sizing: border-box;
  height: 939px;
  background-size: 100%;
  .home1{
    padding: 96px 0 0;
    .h5{
      font-size: 48px;
      color: #000000;
      font-weight: 600;
      margin-bottom: 55px;
    }
    .homeMenu{
      height: 84px;
      margin-bottom: 88px;
      .label{
        width: 100%;
        font-weight: 400;
        font-size: 24px;
        //line-height: 84px;
        text-align: center;
        height: 84px;
        border-bottom: 1px solid #000000;
        color: #000000;
        cursor:pointer;
      }
      .act{
        font-weight: 400;
        font-size: 24px;
        color: #9A40F4;
        border-bottom: 1px solid #9A40F4;
      }
    }
    .homeInfo{
      img{
        width: 768px;
        height: 481px;
        border-radius: 13px 13px 13px 13px;
        margin-right: 87px;
      }
      .contentInfo{
        align-items: flex-start;
        justify-content: flex-start;
        .contentH8{
          font-weight: 600;
          font-size: 53px;
          color: #202020;
          margin-bottom: 27px;
        }
        .contentTxt{
          font-weight: 400;
          font-size: 24px;
          color: #202020;
          text-align: left;
        }
        .freeStoreOpen{
          margin-top: 40px;
          //width: 179px;
          height: 53px;
          padding: 0 20px;
          background: #9A40F4;
          border-radius: 8px 8px 8px 8px;
          font-weight: 600;
          font-size: 21px;
          color: #FFFFFF;
          line-height: 53px;
          text-align: center;
          cursor:pointer;
        }
      }
    }
  }
}
.home2content{
  box-sizing: border-box;
  height: 1200px;
  background-size: 100%;
  .home2{
    padding: 157px 0 0;
    .h5{
      font-weight: 600;
      font-size: 53px;
      color: #202020;
      margin-bottom: 131px;
      text-align: center;
    }
    .homeInfo{
      flex-wrap: wrap;
      .li{
        margin-bottom: 143px;
        img{
          width: 117px;
          height: 117px;
          margin-right: 60px;
        }
        .home2Info{
          align-items: flex-start;
          justify-items: flex-start;
          .txt{
            font-weight: 600;
            font-size: 32px;
            color: #202020;
            margin-bottom: 20px;
          }
          .homeTxt{
            text-align: left;
            width: 516px;
            font-weight: 400;
            font-size: 24px;
            color: #000000;
          }
        }
      }
    }
  }
}

.home3content{

  background: #f8f8f9;
  height: 1232px;
  .H5{
    padding: 132px 0 0;
    font-weight: 600;
    font-size: 75px;
    color: #000000;
    text-align: center;
    margin-bottom: 156px;
  }
  .topOne{
    margin-bottom: 40px;
    img{
      width: 352px;
      height: 123px;
      background: #FFFFFF;
      box-shadow: 13px -13 25px 0px rgba(255,255,255,0.3), -13px 13 25px 0px rgba(217,217,217,0.5);
      border-radius: 20px 20px 20px 20px;
    }

  }
  .topTwo{
    margin-bottom: 40px;
    img{
      width: 352px;
      height: 123px;
      background: #FFFFFF;
      box-shadow: 13px -13 25px 0px rgba(255,255,255,0.3), -13px 13 25px 0px rgba(217,217,217,0.5);
      border-radius: 20px 20px 20px 20px;
    }

  }
  .topThree{
    margin-bottom: 40px;
    img{
      width: 352px;
      height: 123px;
      background: #FFFFFF;
      box-shadow: 13px -13 25px 0px rgba(255,255,255,0.3), -13px 13 25px 0px rgba(217,217,217,0.5);
      border-radius: 20px 20px 20px 20px;
    }
  }
  .topFive{
    margin-bottom: 40px;
    img{
      width: 352px;
      height: 123px;
      background: #FFFFFF;
      box-shadow: 13px -13 25px 0px rgba(255,255,255,0.3), -13px 13 25px 0px rgba(217,217,217,0.5);
      border-radius: 20px 20px 20px 20px;
    }
  }
}

.home4content{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 445px;

  .H5{
    padding: 100px 0 0;
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    color: #FFFFFF;
  }
  .freeStoreOpen{
    margin-top: 60px;
    //width: 333px;
    height: 75px;
    padding: 0 20px;
    background: #FFFFFF;
    //box-shadow: 0 3 11px 0 rgba(0,60,102,0.4);
    border-radius: 5px 5px 5px 5px;
    font-weight: 600;
    font-size: 30px;
    color: #9A40F4;
    line-height: 75px;
    text-align: center;
    cursor:pointer;
  }
}
.block1{
  margin: 0 170px;
}

.tab_box {
  background-color: #faf2fc;
  padding: 100px 100px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;

  h4 {
    font-size: 36px;
    color: #000;
    margin-bottom: 30px;
  }
}
</style>
